// store/authStore.ts
import { defineStore } from "pinia";
import { useCookie, useFetch, useRuntimeConfig } from "#app";
import { useMSAuth } from "@/composables/useMSAuth";

const auth = useCookie<AuthResponse>("auth");
const googleId = "965171958792-h6kvfhutbrd3qgvod4c5f4ve56lhjj4o.apps.googleusercontent.com";
const cookieOptions = { maxAge: 3600 * 24 };

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    authenticated: false,
    loading: false,
    errorAuth: false,
  }),
  getters: {},
  actions: {
    async authenticateUserSSO(credentials: string, type: string) {
      this.loading = true;
      const requestData: any = {
        type,
      };
      if (type === "google") {
        requestData.clientId = googleId;
        requestData.credential = credentials;
      } else if (type === "microsoft") {
        const msAuth = useMSAuth();
        requestData.token = await msAuth.acquireTokenSilent();
      }
      const BASE_URL = useRuntimeConfig().public.BASE_URL;
      // useFetch from nuxt 3
      await $fetch<AuthResponse>(`${BASE_URL}/api/v1/auth/login/`, {
        method: "POST",
        body: requestData,
      })
        .then((data) => this.handleAuthResponse(data))
        .catch(() => {
          this.loading = false;
          this.errorAuth = true;
        });
      const auth = useCookie("token");
      if (auth.value !== undefined) {
        navigateTo("/reports");
        this.errorAuth = true;
      } else {
        this.errorAuth = false;
      }
      this.loading = false;
    },
    async authenticateUser(credentials: AuthLogin) {
      this.loading = true;
      const BASE_URL = useRuntimeConfig().public.BASE_URL;
      // useFetch from nuxt 3
      await $fetch<AuthResponse>(`${BASE_URL}/api/v1/auth/login/`, {
        method: "POST",
        body: {
          type: "basic",
          username: credentials.username,
          password: credentials.password,
        },
      })
        .then((data) => this.handleAuthResponse(data))
        .catch(() => {
          this.loading = false;
          this.errorAuth = true;
        });
      this.loading = false;
    },
    async refreshToken() {
      const BASE_URL = useRuntimeConfig().public.BASE_URL;
      const { data: refreshData } = await useFetch<AuthRefresh>(`${BASE_URL}/api/v1/auth/refresh-token/`, {
        body: {
          refresh: auth.value.refresh,
        },
        method: "POST",
      });
      if (refreshData.value) {
        auth.value.access = refreshData.value.access;
        auth.value.refresh = refreshData.value.refresh;
      }
    },
    setError() {
      this.errorAuth = false;
    },
    logout() {
      const token = useCookie("token");
      const user = useCookie("auth");
      this.authenticated = false; // set authenticated  state value to false
      token.value = null;
      user.value = null;
      sessionStorage.clear();
    },
    handleAuthResponse(data: AuthResponse) {
      if (data) {
        const auth = useCookie<AuthResponse>("auth", cookieOptions);
        auth.value = data;
        const token = useCookie("token", cookieOptions);
        const userId = useCookie<User>("user", cookieOptions);
        userId.value = { username: data.user.username };
        token.value = data.access;
        this.authenticated = true;
      }
    },
  },
});
