import revive_payload_client_4sVQNw7RlN from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_goSqzm8VLR from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/nuxt-papa-parse/dist/runtime/plugin.mjs";
import sentry_client_VdOeuw25Mb from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/.nuxt/sentry-client-config.mjs";
import plugin_client_j7wLaRg9eH from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import msal_YjX35aVrLC from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/plugins/msal.ts";
import errorHandler_x1Y4n5NP4w from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/plugins/errorHandler.ts";
import googleSignIn_DouEUziI2K from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/plugins/googleSignIn.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_goSqzm8VLR,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_client_j7wLaRg9eH,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  msal_YjX35aVrLC,
  errorHandler_x1Y4n5NP4w,
  googleSignIn_DouEUziI2K
]