import { default as _91id_93lfTR7j2BJGMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/audiences/create/[id].vue?macro=true";
import { default as _91id_93q3318qS9JrMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/audiences/detail/[id].vue?macro=true";
import { default as index8AjuNfTKKuMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/audiences/index.vue?macro=true";
import { default as _91id_93SolcEBK5fEMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/campaigns/activate/[id].vue?macro=true";
import { default as _91id_93ktKAGFMvyuMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/campaigns/create/[id].vue?macro=true";
import { default as indexhyoPxAo4gIMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/campaigns/index.vue?macro=true";
import { default as _91id_93R3Nmz3kru2Meta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/campaigns/metrics/[id].vue?macro=true";
import { default as dashboarddPEyeQ7TGfMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/dashboard.vue?macro=true";
import { default as index1dTHliuC2uMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/index.vue?macro=true";
import { default as loginOjpjWFXTCHMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/login.vue?macro=true";
import { default as _91id_9371idY0fIG9Meta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/onboarder/create/[id].vue?macro=true";
import { default as tutorialevfzDj34qeMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/onboarder/create/tutorial.vue?macro=true";
import { default as indexIozgwfwU7WMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/onboarder/index.vue?macro=true";
import { default as reportsgpWkFr9c7YMeta } from "/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/reports.vue?macro=true";
export default [
  {
    name: _91id_93lfTR7j2BJGMeta?.name ?? "audiences-create-id",
    path: _91id_93lfTR7j2BJGMeta?.path ?? "/audiences/create/:id()",
    meta: _91id_93lfTR7j2BJGMeta || {},
    alias: _91id_93lfTR7j2BJGMeta?.alias || [],
    redirect: _91id_93lfTR7j2BJGMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/audiences/create/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93q3318qS9JrMeta?.name ?? "audiences-detail-id",
    path: _91id_93q3318qS9JrMeta?.path ?? "/audiences/detail/:id()",
    meta: _91id_93q3318qS9JrMeta || {},
    alias: _91id_93q3318qS9JrMeta?.alias || [],
    redirect: _91id_93q3318qS9JrMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/audiences/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: index8AjuNfTKKuMeta?.name ?? "audiences",
    path: index8AjuNfTKKuMeta?.path ?? "/audiences",
    meta: index8AjuNfTKKuMeta || {},
    alias: index8AjuNfTKKuMeta?.alias || [],
    redirect: index8AjuNfTKKuMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/audiences/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SolcEBK5fEMeta?.name ?? "campaigns-activate-id",
    path: _91id_93SolcEBK5fEMeta?.path ?? "/campaigns/activate/:id()",
    meta: _91id_93SolcEBK5fEMeta || {},
    alias: _91id_93SolcEBK5fEMeta?.alias || [],
    redirect: _91id_93SolcEBK5fEMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/campaigns/activate/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ktKAGFMvyuMeta?.name ?? "campaigns-create-id",
    path: _91id_93ktKAGFMvyuMeta?.path ?? "/campaigns/create/:id()",
    meta: _91id_93ktKAGFMvyuMeta || {},
    alias: _91id_93ktKAGFMvyuMeta?.alias || [],
    redirect: _91id_93ktKAGFMvyuMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/campaigns/create/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhyoPxAo4gIMeta?.name ?? "campaigns",
    path: indexhyoPxAo4gIMeta?.path ?? "/campaigns",
    meta: indexhyoPxAo4gIMeta || {},
    alias: indexhyoPxAo4gIMeta?.alias || [],
    redirect: indexhyoPxAo4gIMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R3Nmz3kru2Meta?.name ?? "campaigns-metrics-id",
    path: _91id_93R3Nmz3kru2Meta?.path ?? "/campaigns/metrics/:id()",
    meta: _91id_93R3Nmz3kru2Meta || {},
    alias: _91id_93R3Nmz3kru2Meta?.alias || [],
    redirect: _91id_93R3Nmz3kru2Meta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/campaigns/metrics/[id].vue").then(m => m.default || m)
  },
  {
    name: dashboarddPEyeQ7TGfMeta?.name ?? "dashboard",
    path: dashboarddPEyeQ7TGfMeta?.path ?? "/dashboard",
    meta: dashboarddPEyeQ7TGfMeta || {},
    alias: dashboarddPEyeQ7TGfMeta?.alias || [],
    redirect: dashboarddPEyeQ7TGfMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index1dTHliuC2uMeta?.name ?? "index",
    path: index1dTHliuC2uMeta?.path ?? "/",
    meta: index1dTHliuC2uMeta || {},
    alias: index1dTHliuC2uMeta?.alias || [],
    redirect: index1dTHliuC2uMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginOjpjWFXTCHMeta?.name ?? "login",
    path: loginOjpjWFXTCHMeta?.path ?? "/login",
    meta: loginOjpjWFXTCHMeta || {},
    alias: loginOjpjWFXTCHMeta?.alias || [],
    redirect: loginOjpjWFXTCHMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_9371idY0fIG9Meta?.name ?? "onboarder-create-id",
    path: _91id_9371idY0fIG9Meta?.path ?? "/onboarder/create/:id()",
    meta: _91id_9371idY0fIG9Meta || {},
    alias: _91id_9371idY0fIG9Meta?.alias || [],
    redirect: _91id_9371idY0fIG9Meta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/onboarder/create/[id].vue").then(m => m.default || m)
  },
  {
    name: tutorialevfzDj34qeMeta?.name ?? "onboarder-create-tutorial",
    path: tutorialevfzDj34qeMeta?.path ?? "/onboarder/create/tutorial",
    meta: tutorialevfzDj34qeMeta || {},
    alias: tutorialevfzDj34qeMeta?.alias || [],
    redirect: tutorialevfzDj34qeMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/onboarder/create/tutorial.vue").then(m => m.default || m)
  },
  {
    name: indexIozgwfwU7WMeta?.name ?? "onboarder",
    path: indexIozgwfwU7WMeta?.path ?? "/onboarder",
    meta: indexIozgwfwU7WMeta || {},
    alias: indexIozgwfwU7WMeta?.alias || [],
    redirect: indexIozgwfwU7WMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/onboarder/index.vue").then(m => m.default || m)
  },
  {
    name: reportsgpWkFr9c7YMeta?.name ?? "reports",
    path: reportsgpWkFr9c7YMeta?.path ?? "/reports",
    meta: reportsgpWkFr9c7YMeta || {},
    alias: reportsgpWkFr9c7YMeta?.alias || [],
    redirect: reportsgpWkFr9c7YMeta?.redirect,
    component: () => import("/home/runner/work/camp-frontend-nuxt/camp-frontend-nuxt/pages/reports.vue").then(m => m.default || m)
  }
]