export default defineNuxtPlugin((nuxtApp) => {
  // Global error handler for Vue components
  nuxtApp.vueApp.config.errorHandler = (err, instance, info) => {
    console.error("Vue Error:", err);
    console.error("Component instance:", instance);
    console.error("Error Info:", info);

    // Optionally, send this information to a logging service or server
    // Example: sendErrorToService({ error: err, info, component: instance });
  };

  // Global warning handler for Vue components
  nuxtApp.vueApp.config.warnHandler = (msg, instance, trace) => {
    console.warn("Vue Warning:", msg);
    console.warn("Component instance:", instance);
    console.warn("Trace:", trace);

    // Optionally, send this information to a logging service or server
    // Example: sendWarningToService({ message: msg, trace, component: instance });
  };
});
